import * as React from 'react';
import {Fragment, useState} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Avatar, Box, FormControl, Input, InputLabel, Paper} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import {avatarStyle, boxStyle, formStyle, paperSignStyle, submitStyle} from "./style"
import {selectStatus, selectUser, submitConfirmationCodeAsync} from "./sessionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

export const UpdatePassword = () => {
    const previouslyTypedUsername = useAppSelector(selectUser);
    const [recoveryCode, setRecoveryCode] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const navigate = useNavigate()
    const confirmationCodeStatus = useSelector(selectStatus)
    const dispatch = useAppDispatch();

    React.useEffect(()=> {
        if(confirmationCodeStatus === 'submit-confirmation-code-rejected'){
            enqueueSnackbar("Code de confirmation incorrect", {
                autoHideDuration: 5000,
                variant: 'warning'
            });
        }
        if(confirmationCodeStatus === 'submit-confirmation-code-idle'){
            enqueueSnackbar("Mot de passe modifié avec succès", {
                autoHideDuration: 5000,
                variant: 'success'
            });
            navigate('/');
        }
    },[confirmationCodeStatus])

    const updateRecoveryCode = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setRecoveryCode(event.target.value);
    };
    const updatePassword = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value);
        setDisableSubmit(event.target.value !== repeatPassword);
    };
    const updateRepeatPassword = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setRepeatPassword(event.target.value);
        setDisableSubmit(password !== event.target.value);
    };
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault()
        dispatch(submitConfirmationCodeAsync({username: previouslyTypedUsername, recoveryCode, password}))
    }
    return (
        <Box sx={boxStyle}>
            <Paper className="paperSign" sx={paperSignStyle}>
                <Avatar className="avatar" sx={avatarStyle}>
                    <LockOutlined/>
                </Avatar>
                <Fragment>
                    <Typography component="h1" variant="h5">
                        Mot de passe oublié
                    </Typography>
                    <Typography component="h6" variant="subtitle1">
                        Pensez à vérifier vos courriers indésirables.
                    </Typography>
                    <form className="Form" onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="username"
                                        className="inputLabel">
                                Nom d'utilisateur
                            </InputLabel>
                            <Input id="username"
                                   name="username"
                                   autoComplete="username"
                                   value={previouslyTypedUsername}
                                   disabled/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="recoveryCode"
                                        className="inputLabel">
                                Code de confirmation
                            </InputLabel>
                            <Input id="recoveryCode"
                                   name="recoveryCode"
                                   autoComplete="recoveryCode"
                                   value={recoveryCode}
                                   autoFocus
                                   onChange={updateRecoveryCode}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="password">
                                Mot de passe
                            </InputLabel>
                            <Input name="password"
                                   type="password"
                                   id="password"
                                   autoComplete="current-password"
                                   value={password}
                                   onChange={updatePassword}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="repeatPassword">
                                Confirmer le mot de passe
                            </InputLabel>
                            <Input name="repeatPassword"
                                   type="password"
                                   id="repeatPassword"
                                   autoComplete="current-repeatPassword"
                                   value={repeatPassword}
                                   onChange={updateRepeatPassword}/>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="Submit"
                            disabled={disableSubmit}
                            sx={submitStyle}
                        >
                            Enregistrer le nouveau mot de passe
                        </Button>
                        {disableSubmit &&
                            <Typography component="h5" variant="subtitle1">
                                Les mots de passe doivent être identiques
                            </Typography>
                        }
                    </form>
                </Fragment>
            </Paper>
        </Box>
    );
}