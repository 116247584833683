const boxStyle = {
    width: 'auto',
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

const typoStyle = {
    marginTop: 1,
    cursor: 'pointer',
};

const submitStyle = {
    marginTop: 3,
    backgroundColor: 'primary.main',
};

const paperSignStyle = {
    maxWidth : "360px",
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 3,
    paddingBottom: 3
};

const avatarStyle = {
    margin: 1,
    backgroundColor: 'secondary.main',
};

const formStyle = {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
};

const inputLabelStyle = {
    width: 'auto',
    color: 'primary.main'
};

export {
    boxStyle,
    typoStyle,
    submitStyle,
    paperSignStyle,
    avatarStyle,
    formStyle,
    inputLabelStyle
}