import {configureStore} from '@reduxjs/toolkit';
import loginReducer from '../features/session/sessionSlice';

export const store = configureStore({
    reducer: {
        session: loginReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
