import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    fetchSession,
    getConfirmationCode,
    login,
    logout,
    submitConfirmationCode
} from "./sessionAPI";
import {RootState} from "../../app/store";
import {AppMap, ParticipantType, UserRole} from "./session.d"

interface SessionState {
    status: string,
    user: string,
    role: UserRole,
    participantType?: ParticipantType,
    appmap: AppMap,
    logoutSuccess: boolean,
    confirmationCodeSent: boolean
}

export const loginAsync = createAsyncThunk(
    'session/login',
    async ({username, password}: { username: string, password: string }) => {
        return  login(username, password);
    }
);

export const logoutAsync = createAsyncThunk(
    'session/logout',
    async () => {
        return  logout();
    }
);

export const fetchSessionAsync = createAsyncThunk(
    'session/fetchSession',
    async () => {
            return  fetchSession();
    }
);
export const getConfirmationCodeAsync = createAsyncThunk(
    'session/getConfirmationCode',
    async (username: string) => {
        return  getConfirmationCode(username);
    }
);

export const submitConfirmationCodeAsync = createAsyncThunk(
    'session/submitConfirmationCode',
    async ({username, recoveryCode, password}: { username: string, recoveryCode: string, password: string }) => {
        return submitConfirmationCode(username, recoveryCode, password);
    }
);

const initialState = {} as SessionState

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        usernameFilled(state, action) {
            state.user = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.role = action.payload.role;
                state.appmap = action.payload.appmap;
                state.participantType = action.payload.participantType;
            })
            .addCase(logoutAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(logoutAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.logoutSuccess = action.payload.success;
            })
            .addCase(fetchSessionAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSessionAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    state.role = action.payload.role;
                    state.appmap = action.payload.appmap;
                    state.participantType = action.payload.participantType;
                }
            })
            .addCase(getConfirmationCodeAsync.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getConfirmationCodeAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.confirmationCodeSent = action.payload;
            })
            .addCase(submitConfirmationCodeAsync.fulfilled, (state, action) => {
                state.status = 'submit-confirmation-code-idle';
            })
            .addCase(loginAsync.rejected, (state) => {
                state.status = 'login-rejected';
            })
            .addCase(submitConfirmationCodeAsync.rejected, (state) => {
                state.status = 'submit-confirmation-code-rejected';
            })
        ;
    },
})
export const selectStatus = (state : RootState) => state.session.status
export const selectUser = (state: RootState) => state.session.user;
export const selectRole = (state: RootState) => state.session.role;
export const selectParticipantType = (state: RootState) => state.session.participantType;
export const selectAppmap = (state: RootState) => state.session.appmap;
export const selectConfirmationCodeSent = (state: RootState) => state.session.confirmationCodeSent;

export const {usernameFilled} = sessionSlice.actions

export default sessionSlice.reducer