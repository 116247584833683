import React from 'react';
import theme from "./app/theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {RedirectionHandler} from "./RedirectionHandler";
import {Route, Routes} from "react-router-dom";
import {ForgotPassword} from './features/session/ForgotPassword';
import {UpdatePassword} from './features/session/UpdatePassword';
import {Logout} from "./features/session/Logout";
import {Login} from "./features/session/Login";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                <Route path="/" element={<RedirectionHandler><Login/></RedirectionHandler>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="mot-de-passe-oublie" element={<ForgotPassword/>}/>
                <Route path="modifier-le-mot-de-passe" element={<UpdatePassword/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;

