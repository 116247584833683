// TODO add producer/consumer roles in enoapp API bc right now it returns participant
// and then we find the role from the energyType in the operation
export enum UserRole {
    participant = "participant",
    organizer = "organizer",
    admin = "admin",
    observer = "observer",
    visitor = "visitor"
}

export enum ParticipantType {
    consumer = "consumer",
    producer = "producer",
}

export interface AppMap {
    enoconso: string,
    enoapp: string,
    root: string
}