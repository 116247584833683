import * as React from 'react';
import {Fragment, useState} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Avatar, Box, FormControl, Input, InputLabel, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {LockOutlined} from "@mui/icons-material";
import {avatarStyle, boxStyle, formStyle, paperSignStyle, submitStyle} from "./style"
import {getConfirmationCodeAsync, selectUser, usernameFilled} from "./sessionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";


export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const dispatch = useAppDispatch();
    const previouslyTypedUsername = useAppSelector(selectUser)

    React.useEffect(() => setUsername(previouslyTypedUsername), [previouslyTypedUsername]);

    const updateUsername = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setUsername(event.target.value);
        dispatch(usernameFilled(event.target.value))
    };
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault()
        dispatch(getConfirmationCodeAsync(username))
        navigate('/modifier-le-mot-de-passe');
    }
    return (
        <Box sx={boxStyle}>
            <Paper className="paperSign" sx={paperSignStyle}>
                <Avatar className="avatar" sx={avatarStyle}>
                    <LockOutlined/>
                </Avatar>
                <Fragment>
                    <Typography component="h1" variant="h5">
                        Mot de passe oublié
                    </Typography>
                    <form className="Form" onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="username"
                                        className="inputLabel">
                                Nom d'utilisateur
                            </InputLabel>
                            <Input id="username"
                                   name="username"
                                   autoComplete="username"
                                   autoFocus
                                   value={username}
                                   onChange={updateUsername}/>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="Submit"
                            sx={submitStyle}
                        >
                            Recevoir un code de confirmation
                        </Button>
                    </form>
                </Fragment>
            </Paper>
        </Box>
    );
}