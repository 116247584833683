import {UserRole, AppMap, ParticipantType} from "./session.d"

interface UserData {
    username: string,
    role: UserRole,
    participantType?: ParticipantType,
    appmap: AppMap
}

interface LogoutInfo {
    success: boolean,
}


function fetchAppmap() {
    return new Promise<AppMap>(async (resolve, reject) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/appmap`, {credentials: "include"})
        if (res.ok) {
            resolve(res.json());
        }
        else {
            reject(res.status);
        }
    });
}


export function login(username: string, password: string) {
    const body = {email: username, password: password};
    return new Promise<UserData>(async (resolve, reject) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/session/login`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            mode: "cors",
            credentials: "include",
        })
        if (res.ok) {
            resolve(res.json())
        }
        else {
            reject(res.status);
        }
    });
}

export function logout() {
    return new Promise<LogoutInfo>(async (resolve, reject) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/session/logout`, {
            method: "GET",
            mode: "cors",
            credentials: "include",
        })
        if (res.ok) {
            resolve(res.json());
        }
        else {
            reject(res.status);
        }
    });
}

export function fetchSession() {
    return new Promise<UserData>(async (resolve, reject) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/session`, {credentials: "include"})
        if (res.ok) {
            const appmap = await fetchAppmap();
            const sessionRes = await res.json()
            resolve({...sessionRes, appmap});
        }
        else {
            reject(res.status);
        }
    });
}


export function getConfirmationCode(username: string) {
    const body = {email: username};
    return new Promise<boolean>(async (resolve) => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/users/forgot-password`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
                mode: "cors",
                credentials: "include",
            })

            resolve(true);
        } catch (err) {
            resolve(false)
        }
    });
}

export function submitConfirmationCode(username: string, recoveryCode: string, password: string) {
    const body = {email: username, recoveryCode, password};
    return new Promise<any>(async (resolve, reject) => {
        const res : any = await fetch(`${process.env.REACT_APP_API_URL}/users/update-pw-with-code`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            mode: "cors",
            credentials: "include",
        })
        const sessionRes = await res.json()

        if (res.ok) {
            if(!!sessionRes.success){
                resolve(sessionRes)
            } else {
                reject(sessionRes.reason)
            }

        } else {
            reject(res.status);
        }
    });
}