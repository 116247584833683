import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {logoutAsync} from "./sessionSlice";

export const Logout = () => {
    const dispatch = useAppDispatch();
    const [logoutState, setLogoutState] = useState(null)
    const logout = useCallback(async () => {
        const newState: any = await dispatch(logoutAsync());
        setLogoutState(newState)
    }, [])

    useEffect(() => {
        logout().catch(console.error)
    }, [logout]);

    useEffect(() => {
        if (logoutState) {
            window.location.href = "/";
        }
    }, [logoutState])
    return (
        <p>Déconnexion en cours...</p>
    );
}