import {useAppDispatch, useAppSelector} from "./app/hooks";
import {fetchSessionAsync, selectAppmap, selectParticipantType, selectRole} from "./features/session/sessionSlice";
import {useEffect} from "react";
import {ParticipantType, UserRole} from "./features/session/session.d";

interface Props {
    children: JSX.Element,
}

export const RedirectionHandler = ({children}: Props) => {
    const role = useAppSelector(selectRole);
    const participantType = useAppSelector(selectParticipantType);
    const appmap = useAppSelector(selectAppmap);
    const dispatch = useAppDispatch();

    const resetSession = () => {
        dispatch(fetchSessionAsync())
    }

    useEffect(resetSession, [dispatch, role]);

    if (appmap) {
        switch (role) {
            case UserRole.organizer :
            case UserRole.observer :
            case UserRole.admin :
                window.location.href = appmap.enoapp;
                return <div/>
            case UserRole.participant :
                window.location.href = participantType === ParticipantType.consumer ? appmap.enoconso : appmap.enoapp
                return <div/>
            case UserRole.visitor :
            default:
        }
    }
    return (<div>{children}</div>)
};