import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Theme {
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
}

export default createTheme({
    palette: {
        primary: {
            main: '#001e62',
            contrastText: '#fff',
            light: '#84a6cf',
            dark: '#071b48',
        },
        secondary: {
            main: '#F3B617',
            dark: '#F2A900',
            contrastText: '#fff',
            light: '#F4CE36',
        },

    },
    typography: {
        fontSize: 15,
        fontFamily: [
            '"Nunito Sans"',
            'sans-serif'
        ].join(",")
    },
});